// en.js
export default {
  header: {
    home: 'Features',
    download: 'Download',
    helpCenter: 'Help Center',
    signIn: 'Login',
    myAccount: 'My Account',
    changePwd: 'Change Password',
    signOut: 'Sign Out',
    Price: 'Plans',
    aboutUs: 'AboutUs'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  }
}
