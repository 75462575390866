import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Antd from 'ant-design-vue';
import { Button, message, Layout, Result, Icon, Spin, FormModel, Input, Menu, Row, Col, Divider, List, Steps, Badge, Tabs, Card, Carousel, Comment, Rate, Avatar, Tag, InputNumber, Popconfirm, Descriptions, Table, Dropdown,Collapse } from "ant-design-vue";
// import 'ant-design-vue/dist/antd.css';
import '@/assets/js/iconfont'
import '@/assets/css/index.less'
//国际化
import i18n from './lang'

Vue.config.productionTip = false
Vue.prototype.$message = message
message.config({
  top: '200px'
})
// Vue.use(Antd)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Result)
Vue.use(Icon)
Vue.use(Spin)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(List)
Vue.use(Steps)
Vue.use(Badge)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(Comment)
Vue.use(Rate)
Vue.use(Avatar)
Vue.use(Tag)
Vue.use(InputNumber)
Vue.use(Popconfirm)
Vue.use(Descriptions)
Vue.use(Table)
Vue.use(Dropdown)
Vue.use(Collapse)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
